<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-3 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Attribute Value Product</p>
          </div>
        </div>
        <!-- ------------------------- -->
        <div class="row m-0 px-3">
          <!-- --------------------- -->
          <div class="col-12 col-xl-6 mb-2">
            <div class="form-group row">
              <label>CatCode</label>
              <div class="col-sm-10">
                <div class="input-group   m-0 p-0 ">
                  <input
                    type="text"
                    class="form-control"
                    v-model="data.cattext"
                    @click="callModalcatcode()"
                  />
                  <div class="input-group-append">
                    <button
                      class="input-group-text px-3"
                      @click="Clearcatcode()"
                    >
                      x
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ------------------------------- -->
          <div class="col-12 col-xl-6 mb-2">
            <div class="row m-0 p-0">
              <div class="col-9 m-0 p-0">
                <div class="form-group  row">
                  <label>Attribute</label>
                  <div class="col-sm-10">
                    <multiselect
                      :disabled="attributelist.length === 0"
                      :key="data.catCode"
                      v-model="data.attribute"
                      :options="attributelist"
                      :clear-on-select="true"
                      :allow-empty="false"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      label="attrlabelTH"
                      track-by="attrlabelTH"
                    >
                      <div
                        class="option__desc isDisabled"
                        slot="option"
                        slot-scope="props"
                      >
                        <span>{{ props.option.attrlabelTH }} </span>
                      </div>
                    </multiselect>
                  </div>
                </div>
              </div>

              <div class="col-3 m-0 p-0">
                <button
                  type="button"
                  class="btn bt-vfx   mx-1 "
                  @click="gotoattr()"
                  :disabled="data.attribute === ''"
                >
                  Attribute Page
                </button>
              </div>
            </div>
          </div>
          <!-- ------------------------------- -->
          <div class="col-12 col-xl-6 mb-2">
            <div class="form-group  row">
              <label>Option</label>
              <div class="col-sm-10">
                <multiselect
                  :disabled="attributeoption.length === 0"
                  :key="data.catCode"
                  v-model="data.attributeoption"
                  :options="attributeoption"
                  :clear-on-select="true"
                  :allow-empty="true"
                  deselect-label=""
                  select-label=""
                  selected-label=""
                  label="labelTH"
                  track-by="labelTH"
                >
                  <div
                    class="option__desc isDisabled"
                    slot="option"
                    slot-scope="props"
                  >
                    <span>{{ props.option.labelTH }} </span>
                  </div>
                </multiselect>
              </div>
            </div>
          </div>
          <!-- ------------------------------- -->
          <div class="col-12 col-xl-6 mb-2">
            <div class="form-check">
              <input
                type="checkbox"
                class="form-check-input"
                id="incat"
                v-model="data.incat"
              />
              <label class="form-check-label" for="incat"
                >สินค้าที่อยู่ภายใต้ CatCode</label
              >
            </div>
          </div>
          <!-- ------------------------------- -->
          <!-- <div class="col-12 col-xl-6 mb-2">
            <div class="form-group  row">
              <label>To Option</label>
              <div class="col-sm-10">
                <multiselect
                  :disabled="attributeoption.length === 0"
                  :key="data.catCode"
                  v-model="data.toattributeoption"
                  :options="attributeoption"
                  :clear-on-select="true"
                  :allow-empty="false"
                  deselect-label=""
                  select-label=""
                  selected-label=""
                  label="labelTH"
                  track-by="labelTH"
                >
                  <div
                    class="option__desc isDisabled"
                    slot="option"
                    slot-scope="props"
                  >
                    <span>{{ props.option.labelTH }} </span>
                  </div>
                </multiselect>
              </div>
            </div>
          </div> -->
          <!-- ------------------------------- -->
          <div class="col-12 mb-3">
            <div class="row m-0 p-0">
              <div class="col-12 col-xl-6">
                <button
                  type="button"
                  class="btn bt-main btn-sm mx-1 px-4"
                  @click="confirm()"
                >
                  <span class="mx-1" v-html="$svg_icon.search"></span>ค้นหา
                </button>
                <button
                  type="button"
                  class="btn bt-main1 btn-sm mx-1 px-4"
                  @click="redata()"
                >
                  <span class="mx-1" v-html="$svg_icon.reset"></span>รีเซ็ต
                </button>
              </div>
              <div class="col-12 col-xl-6 text-xl-right">
                <button
                  type="button"
                  class="btn bt-main1 btn-sm mx-1 px-4"
                  @click="downloadoriginfile()"
                  :disabled="items.length === 0"
                >
                  <span class="mx-1" v-html="$svg_icon.download"></span
                  >ดาวน์โหลดไฟล์ต้นฉบับ
                </button>
                <button
                  type="button"
                  class="btn bt-main btn-sm mx-1 px-4"
                  @click="$refs.file.click()"
                  :disabled="items.length === 0 || data.incat === 0"
                >
                  <span class="mx-1" v-html="$svg_icon.upload"></span
                  >เลือกจากไฟล์ต้นฉบับ
                </button>

                <input
                  type="file"
                  multiple
                  @change="UploadFile($event)"
                  ref="file"
                  style="display: none"
                />
              </div>
            </div>
          </div>

          <!-- ------------------------------------------------- -->
          <div class="col-12">
            <div class="row mb-3 p-0">
              <div class="col-5">
                <p class="px-3 font-600 w-100" style="display: flex">
                  จำนวน&nbsp;
                  <span class="text-main">{{
                    new Intl.NumberFormat().format(totalRows)
                  }}</span>
                  &nbsp;รายการ
                </p>
              </div>
              <div class="col-7 text-right">
                <div class="row m-0 p-0">
                  <div class="col-9">
                    <div class="row m-0 p-0">
                      <div class="col-4">New Option</div>
                      <div class="col-8">
                        <multiselect
                          :disabled="
                            data.selectSku.length === 0 || data.incat === 0
                          "
                          :key="data.catCode"
                          v-model="data.toattributeoption"
                          :options="attributeoption"
                          :clear-on-select="true"
                          :allow-empty="false"
                          deselect-label=""
                          select-label=""
                          selected-label=""
                          label="labelTH"
                          track-by="labelTH"
                        >
                          <div
                            class="option__desc isDisabled"
                            slot="option"
                            slot-scope="props"
                          >
                            <span>{{ props.option.labelTH }} </span>
                          </div>
                        </multiselect>
                      </div>
                    </div>
                  </div>
                  <div class="col-3">
                    <button
                      type="button"
                      class="btn bt-bnb px-2"
                      :disabled="
                        data.selectSku.length === 0 ||
                          data.toattributeoption === '' ||
                          data.incat === 0
                      "
                      @click="saveChangeAttrVal()"
                    >
                      บันทึก
                      {{
                        new Intl.NumberFormat().format(data.selectSku.length)
                      }}
                      รายการ
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <!-- -------------------------------- table ------------------------------ -->
            <b-table
              :key="mykey"
              :empty-text="'ไม่พบข้อมูล / No Data'"
              :fields="fields"
              :items="items"
              sticky-header="70vh"
              outlined
              hover
              show-empty
              responsive
              class="font-0-8s"
            >
              <template #head(check)="">
                <div class="form-check">
                  <input
                    class="form-check-input position-static"
                    type="checkbox"
                    :disabled="items.length === 0 || data.incat === 0"
                    value="true"
                    v-model="isSelectAll"
                  />
                </div>
              </template>
              <template #cell(check)="row">
                <div class="form-check">
                  <input
                    class="form-check-input position-static"
                    type="checkbox"
                    :disabled="items.length === 0 || data.incat === 0"
                    :value="row.item.skCode"
                    v-model="data.selectSku"
                  />
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <Modalcatcode
      :key="reModalcatcode"
      confirmlvl="3"
      @return_data="return_data"
      :saveStroe="true"
    />
    <Modalprogress :key="reModalprogress" />
  </div>
</template>

<script>
import Modalcatcode from "@/components/common/Modalcatcode.vue";
import Modalprogress from "@/components/common/Modalprogress.vue";

export default {
  name: "AttributeValueProductList",
  components: { Modalcatcode, Modalprogress },
  data() {
    return {
      mykey: Date.now() + 25,
      reModalcatcode: Date.now() + 5,
      reModalprogress: Date.now() + Date.now() + 5,
      isSelectAll: false,
      data: {
        cattext: "",
        catCode: "",
        attribute: "",
        attributeoption: "",
        toattributeoption: "",
        incat: 1,
        selectSku: [],
      },
      attributelist: [],
      attributeoption: [],

      items: [],
      totalRows: 0,
      fields: [
        {
          key: "check",
          label: "",
        },
        { key: "skCode", label: "skCode" },
        // { key: "catCode", label: "catCode" },

        { key: "prNameTH", label: "prNameTH" },
        { key: "attrGroupName", label: "attrGroupName" },
        { key: "catCode", label: "catCode", class: "text-center" },
        { key: "showOnWeb", label: "showOnWeb", class: "text-center" },

        {
          key: "enrich",
          label: "enrich",
          class: "text-center",
          thStyle: { width: "8%" },
        },
      ],
    };
  },
  watch: {
    isSelectAll: function(v) {
      if (v) {
        this.selectall();
      } else if (this.data.selectSku.length === this.items.length) {
        this.data.selectSku = [];
      }
    },
    "data.incat": function(v) {
      this.items = [];
      if (v) {
        this.data.incat = 1;
      } else {
        this.data.incat = 0;
      }
    },
    "data.catCode": function(v) {
      this.data.attribute = "";
      this.data.attributeoption = "";
      this.data.toattributeoption = "";
      this.items = [];

      if (!!v) {
        this.get_attribute(v);
      } else {
        this.attributelist = [];
      }
    },
    "data.attribute": function(v) {
      this.data.attributeoption = "";
      this.data.toattributeoption = "";
      this.items = [];

      if (!!v) {
        let { attrId } = v;
        this.get_option(attrId);
      } else {
        this.attributeoption = [];
      }
    },
    "data.attributeoption": function(v) {
      this.items = [];
    },
    "data.selectSku": function(v) {
      if (this.items.length > 0 && v.length === this.items.length) {
        this.isSelectAll = true;
      } else {
        this.isSelectAll = false;
      }
    },
    items: function(v) {
      if (v.length === 0) {
        this.data.selectSku = [];
        this.isSelectAll = false;
      }
      this.totalRows = this.items.length;
    },
  },
  mounted() {},
  methods: {
    async gotoattr() {
      let { attribute } = this.data;
      if (!!attribute) {
        let { attrId } = attribute;
        const routeData = this.$router.resolve({
          name: "Attributes-Edit",
          query: { attr: attrId },
        });

        window.open(routeData.href, "_blank");
      }
    },
    async UploadFile(e) {
      let readfile = await this.$serviceMain.ReadExcel(e.target.files[0]);

      const filter = readfile.reduce((a, o) => (a.push(o.skCode), a), []);
      this.data.selectSku = filter;
      this.$refs.file.value = null;
      this.mykey = Date.now() + 25;
    },
    async downloadoriginfile() {
      let date = `${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDay()}-${new Date().getTime()}`;

      const json = this.items.reduce(
        (a, o) => (a.push({ skCode: o.skCode, prNameTH: o.prNameTH }), a),
        []
      );

      let name = `ไฟล์ต้นฉบับ(${date}).xlsx`;
      this.$serviceMain.JsonToExcat(json, name);
    },
    async delay(time) {
      return new Promise(async (resolve, reject) => {
        setTimeout(() => {
          resolve("OK");
        }, time);
      });
    },
    async saveChangeAttrVal() {
      try {
        this.reModalprogress = Date.now() + Date.now();
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          // this.reModalprogress = Date.now() + Date.now();
          this.$bvModal.show("Modalprogress");
          let {
            selectSku,
            toattributeoption,
            catCode,
            attribute,
            attributeoption,
          } = this.data;

          let { attrId, attrlabelTH: labelAttr } = attribute;
          let { id: option_id, labelTH: optionLable } = toattributeoption;
          this.$store.dispatch("progressActions/renumload");
          this.$store.dispatch("progressActions/setnumall", selectSku.length);
          let num = 0;
          let max = 20;
          for await (let v of selectSku) {
            let old_val = await this.get_data_value(v, catCode);
          
            if (old_val.length > 0) {
              let { attrDetail, attrGroupId, skCode } = old_val[0];
              let pas_json = JSON.parse(attrDetail);
              let arr = pas_json;

              arr.forEach((object) => {
                object["id"] = object.option_id;
                object["labelTH"] = object.optionLable;
              });

              let index_att = arr.findIndex((e) => e.attrId === attrId);
              if (
                this.$serviceMain.chechnull(attributeoption) === "" &&
                index_att === -1
              ) {
                //  ----- กรณีที่ยังไม่ที เเล้ว ไม่เจอ -----
                arr.push({
                  attrGroupId: attrGroupId,
                  skCode: skCode,
                  attrId: attrId,
                  option_id: option_id,
                  labelAttr: labelAttr,
                  optionLable: optionLable,
                  id: option_id,
                  labelTH: optionLable,
                });
                // await this.save_new_value(arr);
                // this.$store.dispatch("progressActions/addnumload");
              } else if (index_att !== -1) {
                arr[index_att].optionLable = optionLable;
                arr[index_att].option_id = option_id;
                arr[index_att].labelTH = optionLable;
                arr[index_att].id = option_id;

                // console.log(arr);
                // await this.save_new_value(arr);
                // this.$store.dispatch("progressActions/addnumload");
              }
              await this.save_new_value(arr);
              this.$store.dispatch("progressActions/addnumload");
            }

            if (num === max) {
              await this.delay(2000);
              num = 0;
            }
            num++;
          }

          //  this.$bvModal.hide("Modalprogress");
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
          this.confirm();
        }
      } catch (error) {
        console.log(error);
        this.$bvModal.hide("Modalprogress");
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_data_value(skCode, catWeb) {
      return new Promise(async (resolve, reject) => {
        try {
          let data = { skuCode: skCode, catWeb: catWeb };

          let getAPI = await this.$serviceAPI.call_API_NO_LOAD(
            "post",
            `attribute/get_value_attribute_porduct_by_sku`,
            data,
            1
          );

          resolve(getAPI.data);
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    async save_new_value(data) {
      return new Promise(async (resolve, reject) => {
        try {
          // console.log(this.selectattribute);
          let getAPI = await this.$serviceAPI.call_API_NO_LOAD(
            "post",
            `attribute/update_product_attribute_value`,
            data,
            1
          );
          resolve(getAPI);
          this.$emit("return_Action");
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },
    async selectall() {
      let object = this.items;

      const filtered = object.reduce((a, o) => (a.push(o.skCode), a), []);
      // console.log(filtered);

      this.data.selectSku = filtered;
    },
    redata() {
      this.data = {
        cattext: "",
        catCode: "",
        attribute: "",
        attributeoption: "",
        toattributeoption: "",
        incat: 1,
      };
    },
    async validate() {
      return new Promise((resolve, reject) => {
        let { catCode, attribute, attributeoption } = this.data;

        if (!!!catCode) {
          resolve(`Please enter catCode / กรุณาระบุ catCode`);
        }
        if (!!!attribute) {
          resolve(`Please enter attribute / กรุณาระบุ attribute`);
        }
        // if (!!!attributeoption) {
        //   resolve(`Please enter Option / กรุณาระบุ Option`);
        // }
        resolve("OK");
      });
    },
    async confirm() {
      try {
        let vali = await this.validate();
        if (vali !== "OK") {
          throw vali;
        }
        // console.log(this.data);
        let data = {
          catCode: this.data.catCode,
          incat: this.data.incat,
          attrId: this.data.attribute?.attrId,
          option_id: this.data.attributeoption?.id || "",
        };

        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/multi_get_attribute_value_product`,
          data,
          1
        );

        this.items = getAPI.data;
        this.totalRows = this.items.length;
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_option(attrid) {
      try {
        let data = { attrId: attrid };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_attribute_option`,
          data,
          1
        );

        // console.log(getAPI.data);
        this.attributeoption = getAPI.data;
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async get_attribute(cat) {
      try {
        let data = { attrGroupId: cat };
        let getAPI = await this.$serviceAPI.call_API(
          "post",
          `attribute/get_group_attribute_list`,
          data,
          1
        );

        //  console.log(getAPI.data);
        this.attributelist = getAPI.data;
        //   this.attributb_list = arr;
        //   this.attributb_lists = arr;
        //   // console.log(this.attributb_list);
        //   this.totalRows = this.attributb_lists.length;
        //   this.data.attrGroupId = this.$route.query?.attrGroupId;
      } catch (error) {
        console.log(error);
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    return_data(data) {
      let { catcode, selectnow } = data;
      this.data.cattext = selectnow;
      this.data.catCode = catcode;
    },
    callModalcatcode() {
      this.reModalcatcode = Date.now();
      setTimeout(() => {
        this.$bvModal.show("Modalcatcode");
      }, 100);
    },
    Clearcatcode() {
      this.data.cattext = "";
      this.data.catCode = "";

      this.$store.dispatch("categoryActions/resetcategoryweb");
    },
  },
};
</script>

<style></style>
