<template>
  <b-modal
    id="Modalprogress"
    hide-footer
    hide-header
    no-close-on-backdrop
    size="sm"
    title=""
  >
    <div class="row">
      <div class="col-12">
        <p class="font-600 text-center text-main font-1-2s">
          กำลังดำเนินการ...
        </p>
        <!-- <b-progress
          :value="
            ($store.getters['progressActions/get_numload'] * 100) /
              $store.getters['progressActions/get_numall']
          "
          max="100"
          variant="success"
          show-progress
          animated
        ></b-progress> -->
        <b-progress max="100" show-progress animated variant="bnb">
          <b-progress-bar
            :value="
              ($store.getters['progressActions/get_numload'] * 100) /
                $store.getters['progressActions/get_numall']
            "
            :label="
              `${(($store.getters['progressActions/get_numload'] * 100) /
                $store.getters['progressActions/get_numall']).toFixed(0)} %`
            "
          ></b-progress-bar>
        </b-progress>
        <!-- <div class="progress">
          <div
            class="progress-bar bg-success progress-bar-striped"
            role="progressbar"
            :aria-valuenow="
              ($store.getters['progressActions/get_numload'] * 100) /
                $store.getters['progressActions/get_numall']
            "
            aria-valuemin="0"
            aria-valuemax="100"
          >
            {{
              ($store.getters["progressActions/get_numload"] * 100) /
                $store.getters["progressActions/get_numall"]
            }}
            %
          </div>
        </div> -->
      </div>

      <div
        class="col-12 mt-2 text-center"
        v-if="
          $store.getters['progressActions/get_numall'] ===
            $store.getters['progressActions/get_numload']
        "
      >
        <button class="btn bt-main mx-1 px-4" @click="hide()">
          ตกลง
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "Modalprogress",

  methods: {
    hide() {
      this.$bvModal.hide("Modalprogress");
    },
  },
};
</script>

<style scoped>
.cat-height {
  height: 55vh;
  overflow-y: auto;
}
</style>
